import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { clearData, setData } from "../redux/actionTypes/rootAction";
import { APIENDPOINT } from "../global/Const";
import { postData } from "../global/Api";
import Spinner from "../components/common/Spinner";
import Header from "../components/common/Header";
import '../css/main.css';

function Dashboard() {
    const { counter_id } = useParams();
    const [title, setTitle] = useState('');
    const [mainLogo, setMainLogo] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [apiResponse, setApiResponse] = useState([]);
    const [errormessage, seterrormessage] = useState([]);
    const state = useSelector((state) => state);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getCurrentLocation = () => {
        return new Promise((resolve, reject) => {
            if (!navigator.geolocation) {
                const errorMessage = "Geolocation is not supported by this browser.";
                toast.error(errorMessage);
                reject(errorMessage);
                return;
            }

            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    resolve({ latitude, longitude });
                },
                (error) => {
                    let errorMessage;

                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            errorMessage = "Location services are disabled. Please enable location services in your device settings to proceed.";
                            showPermissionDialog();
                            break;
                        case error.POSITION_UNAVAILABLE:
                            errorMessage = "Location information is unavailable.";
                            break;
                        case error.TIMEOUT:
                            errorMessage = "The request to get user location timed out.";
                            break;
                        case error.UNKNOWN_ERROR:
                            errorMessage = `An unknown error occurred: ${error.message}`;
                            break;
                        default:
                            errorMessage = `Error: ${error.message}`;
                    }

                    // toast.error(errorMessage);
                    seterrormessage(errorMessage);
                    reject(errorMessage);
                }
            );
        });
    };

    const showPermissionDialog = () => {
        const { browser, device } = getBrowserAndDevice();
        let message = '';

        if (device === 'Mobile') {
            message += `Location access is required to use this feature. Please follow the instructions below to enable location services on your mobile device:\n\n`;
            if (browser === 'Chrome') {
                message += `**Chrome Mobile:** Go to Settings > Site settings > Location, and ensure location access is enabled.\n`;
            } else if (browser === 'Firefox') {
                message += `**Firefox Mobile:** Go to Settings > Privacy > Location, and ensure location access is enabled.\n`;
            } else if (browser === 'Safari') {
                message += `**Safari Mobile:** Go to Settings > Safari > Location, and make sure location access is enabled.\n`;
            } else {
                message += `For other mobile browsers, please check your browser’s settings to enable location access.\n`;
            }
        } else {
            message += `Location access is required to use this feature. Please follow the instructions below to enable location services on your desktop:\n\n`;
            if (browser === 'Chrome') {
                message += `**Chrome:** Go to Settings (chrome://settings) > Privacy and security > Site settings > Location, and ensure location access is enabled.\n`;
            } else if (browser === 'Firefox') {
                message += `**Firefox:** Go to Options (about:preferences) > Privacy & Security > Permissions > Location, and make sure location access is allowed.\n`;
            } else if (browser === 'Safari') {
                message += `**Safari:** Go to Preferences (Safari > Preferences) > Websites tab > Location, and allow location access for this site.\n`;
            } else if (browser === 'Edge') {
                message += `**Edge:** Go to Settings > Cookies and site permissions > Location, and make sure location access is enabled.\n`;
            } else if (browser === 'Internet Explorer') {
                message += `**Internet Explorer:** Go to Tools > Internet Options > Privacy tab > Location, and ensure location access is enabled.\n`;
            } else {
                message += `For other browsers, please check your browser’s settings to enable location access.\n`;
            }
        }

        alert(message);
    };

    const getBrowserAndDevice = () => {
        const userAgent = navigator.userAgent;
        let browser = '';
        let device = '';

        if (userAgent.includes('Firefox')) {
            browser = 'Firefox';
        } else if (userAgent.includes('Chrome') && !userAgent.includes('Edg')) {
            browser = 'Chrome';
        } else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
            browser = 'Safari';
        } else if (userAgent.includes('Edg')) {
            browser = 'Edge';
        } else if (userAgent.includes('MSIE') || userAgent.includes('Trident/')) {
            browser = 'Internet Explorer';
        }

        device = /Mobi|Android/i.test(userAgent) ? 'Mobile' : 'Desktop';

        return { browser, device };
    };


    useEffect(() => {
        dispatch(clearData());
        getPlaceByCounter();
    }, [dispatch, counter_id]);

    const getPlaceByCounter = async () => {
        try {
            const { latitude, longitude } = await getCurrentLocation();
            const formData = {
                counter_slug: counter_id.toString(),
                user_lat: latitude.toString(),
                user_long: longitude.toString()
            };
            const responseApi = await postData(APIENDPOINT.GETALLSIGHTSEEN, formData);

            if (responseApi.response_code === 200 && responseApi.status === true) {
                setApiResponse(responseApi.data);
                setTitle(responseApi.data.title);
                setMainLogo(responseApi.data.logo);
                const data = {
                    counter_slug: counter_id,
                    user_lat: latitude.toString(),
                    user_long: longitude.toString(),
                    main_logo: responseApi.data.logo
                };
                dispatch(setData(data));
            } else {
                // toast.error(responseApi.message || "Something Went Wrong !!");
                seterrormessage(responseApi.message || "Something Went Wrong !!");
            }
        } catch (error) {
            console.log(error.message)
            // toast.error(`Error: ${error.message || 'Something Went Wrong'}`);
        } finally {
            setIsLoading(false);
        }
    };

    const handleOnClick = (item) => {
        const data = {
            ...state,
            sightseeing_id: item.id,
            sightseeing_name: item.name,
        };
        dispatch(setData(data));
        navigate(`/counter/${counter_id}/${item.id}`);
    };

    const screenWidth = window.screen.width < 600;

    return (
        <React.Fragment>
            <div className="App">
                <Header mainlogo={mainLogo} style={2} screen={"Dashboard"} counterId={counter_id} />
                <Spinner loading={isLoading} />
                <div className="Content" style={{ backgroundImage: screenWidth ? `url(${apiResponse?.photo})` : null }}>
                    <div className="container">
                        <div className="mt-4">
                            <h3 className="text-center">
                                Welcome to <b className="d-block">The City Palace, Udaipur</b>
                            </h3>
                            {apiResponse?.list ?
                                <>
                                    <h6 className="text-center mb-0 pt-3">
                                        You are at <span className="fw-bold text-color-primary">{title}</span> Entry Gate.
                                    </h6>
                                    <h6 className="text-center">Please select the ticket you want to book.</h6>
                                    <div className="row px-4">
                                        {apiResponse.list.map((item, index) => (
                                            <div className="col-6 p-3" key={index}>
                                                <div
                                                    className="card color-primary card-custom d-flex justify-content-center p-1"
                                                    style={{ minHeight: 65, maxHeight: 65 }}
                                                    onClick={() => handleOnClick(item)}
                                                >
                                                    <div className="text-center">
                                                        <p className="card-title text-light fs-14 m-0" style={{ fontWeight: 500 }}>
                                                            {item.name}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </> :
                                !isLoading && <p className="fs-6 text-center py-5 text-danger">{errormessage}</p>
                            }
                        </div>
                    </div>
                    {/* Uncomment if you have a footer */}
                    {/* <div className="footer-container">
                        <img src={footerImage} alt="footer-logo" className="footer-image" />
                    </div> */}
                </div>
            </div>
        </React.Fragment>
    );
}

export default Dashboard;
