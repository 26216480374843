import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { toast } from 'react-toastify';

//SVG
import { getCurrentDate, getCurrentLocation } from '../global/Function';
import { APIENDPOINT } from '../global/Const';
import { postData } from '../global/Api';
import { setData } from '../redux/actionTypes/rootAction';
import Header from '../components/common/Header';
import Spinner from '../components/common/Spinner';
import '../css/main.css';
import RenderRazorpay from './RenderRazorpay';

function Revieworder() {
    const { counter_id, sightseen_id, product_id } = useParams();
    const [isSubscribed, setisSubscribed] = useState(false);
    const [isAccepted, setisAccepted] = useState(true);
    const [modal, setModal] = useState(false);
    const [isLoading, setisLoading] = useState(false)
    const [email, setEmail] = useState(false);
    const [displayRazorpay, setDisplayRazorpay] = useState(false);
    const [orderDetails, setOrderDetails] = useState({});
    const state = useSelector((state) => state);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const currentDate = getCurrentDate()
    const toggle = () => setModal(!modal);

    const handleCreateOrder = async (response, callback) => {
        setOrderDetails({
            key: response.data.key_id,
            amount: response.data.payment.amount_due,
            currency: response.data.payment.currency,
            name: response.data.company_name,
            description: null,
            image: response.data.company_logo,
            order_id: response.data.payment.id,
            callback_url: callback,
            prefill: {
                name: state.buyer_details.name,
                email: state.buyer_details.email,
                contact: state.buyer_details.mobile
            },
            notes: {
                address: state.buyer_details.address,
            },
            theme: {
                color: "#AA4332"
            },
            payment_gateway: response.data.payment_gateway
        });
        setDisplayRazorpay(true);
    };

    const sortedTicketItems = state.sale_head.sort((a, b) => a.display_order - b.display_order);

    const getBrowserAndDevice = () => {
        const userAgent = navigator.userAgent;
        let browser = '';
        let device = '';

        if (userAgent.includes('Firefox')) {
            browser = 'Firefox';
        } else if (userAgent.includes('Chrome') && !userAgent.includes('Edg')) {
            browser = 'Chrome';
        } else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
            browser = 'Safari';
        } else if (userAgent.includes('Edg')) {
            browser = 'Edge';
        } else if (userAgent.includes('MSIE') || userAgent.includes('Trident/')) {
            browser = 'Internet Explorer';
        }

        device = /Mobi|Android/i.test(userAgent) ? 'Mobile' : 'Desktop';

        return { browser, device };
    };

    function showPopupDialog() {
        const { browser, device } = getBrowserAndDevice();
        let message = '';
        message += `Popups are required to complete this action. Please follow the instructions below to allow popups for this site:\n\n`;
        if (device === 'Mobile') {
            message += `Popup blocking settings are generally managed by the operating system on mobile devices. Ensure that popups are allowed in your device's browser settings.\n`;
        } else {
            if (browser === 'Chrome') {
                message += `**Chrome:** Go to Settings (chrome://settings) > Privacy and security > Site settings > Pop-ups and redirects, and allow popups for this site.\n`;
            } else if (browser === 'Firefox') {
                message += `**Firefox:** Go to Options (about:preferences) > Privacy & Security > Permissions > Block pop-up windows, and add this site to the allowed list.\n`;
            } else if (browser === 'Safari') {
                message += `**Safari:** Go to Preferences (Safari > Preferences) > Websites tab > Pop-up Windows, and allow popups for this site.\n`;
            } else if (browser === 'Edge') {
                message += `**Edge:** Go to Settings > Cookies and site permissions > Pop-ups and redirects, and ensure popups are allowed.\n`;
            } else if (browser === 'Internet Explorer') {
                message += `**Internet Explorer:** Go to Tools > Internet Options > Privacy tab > Pop-up Blocker settings, and add this site to the allowed list.\n`;
            } else {
                message += `For other browsers, please check your browser’s settings to allow popups for this site.\n`;
            }
        }
        alert(message);
    }

    const handleonClick = async () => {
        if (isAccepted) {
            setisLoading(true)
            const { latitude, longitude } = await getCurrentLocation()
            let temp_sale_head = []
            sortedTicketItems.map((item) => {
                temp_sale_head.push({ id: item.id, no_of_tickets: item.no_of_tickets, is_value_added_process: item.is_value_added_process })
            })
            // setisLoading(false)
            const basecallbackurl = window.location.href.split("counter")[0]
            const callbackRetuenUrl = `${basecallbackurl}counter/${counter_id}/${sightseen_id}/${product_id}/confirm-booking/`
            const formData = {
                counter_slug: state.counter_slug,
                user_lat: latitude.toString(),
                user_long: longitude.toString(),
                sightseeing_id: state.sightseeing_id,
                product_id: state.product_id,
                terms_condition_accepted: isAccepted,
                is_subscribed: isSubscribed,
                buyer_details: {
                    name: state.buyer_details.name,
                    email: state.buyer_details.email,
                    mobile: state.buyer_details.mobile,
                    verification_token: state.buyer_details.verification_token,
                    company: state.buyer_details.company,
                    address: state.buyer_details.address,
                    state_id: state.buyer_details.state_id,
                    city_id: state.buyer_details.city_id,
                    pincode: state.buyer_details.pincode,
                    gst_no: state.buyer_details.gst_no,
                    id_proof: state.buyer_details.id_proof,
                    id_proof_no: state.buyer_details.id_proof_no,
                },
                billing_type_id: "666fc74d5be76a62624d9fad",
                sale_head: temp_sale_head,
                callback_url: callbackRetuenUrl
            }
            const responseApi = await postData(APIENDPOINT.SUBMITBOOKING, formData);
            if (responseApi.response_code === 200 && responseApi.status === true) {
                const data = {
                    ...state,
                    user_lat: latitude.toString(),
                    user_long: longitude.toString(),
                    is_subscribed: isSubscribed,
                    terms_condition_accepted: isAccepted
                };
                await dispatch(setData(data));
                if (responseApi.data.payment_gateway === "HDFC") {
                    // window.open(responseApi.data.payment_url)
                    // setisLoading(false)

                    let popupBlocked = false;
                    const popup = window.open(responseApi.data.payment_url);
                    if (popup === null) {
                        popupBlocked = true;
                    }
                    if (popupBlocked) {
                        showPopupDialog()
                        setisLoading(false);
                    } else {
                        setisLoading(false);
                    }
                } else {
                    const callback = process.env.PUBLIC_URL + `/counter/${counter_id}/${sightseen_id}/${product_id}/confirm-booking/${responseApi.data.order_id}`
                    handleCreateOrder(responseApi, callback)
                    setisLoading(false)
                }
            } else if (responseApi.message) {
                toast.error(responseApi.message)
                setisLoading(false)
            } else {
                toast.error("Something Went Wrong !!")
                setisLoading(false)
            }
        } else {
            toast.warn("Please Agree to the terms and conditions");
        }
    };

    const calculateTotal = () => {
        return state.sale_head.reduce((total, item) => total + item.no_of_tickets * item.price, 0);
    };

    const calculateGST = () => {
        const totalGST = state.sale_head.reduce((totalGST, item) => {
            const itemGST = (item.price * (item.gst || 0)) / 100;
            return totalGST + item.no_of_tickets * itemGST;
        }, 0);

        return parseFloat(totalGST.toFixed(2));
    };

    const roundAmount = () => {
        const total = parseFloat(calculateTotal()).toFixed(2)
        const cgst = parseFloat(calculateGST() / 2).toFixed(2)
        const sgst = parseFloat(calculateGST() / 2).toFixed(2)
        const amount = parseFloat(total) + parseFloat(cgst) + parseFloat(sgst)
        const roundedAmount = parseFloat(amount).toFixed(2);
        const decimalPart = parseFloat(roundedAmount - Math.floor(roundedAmount)).toFixed(2);

        if (decimalPart <= 0.5) {
            return -Math.abs(parseFloat(decimalPart).toFixed(2));
        } else {
            return Math.abs(parseFloat(1 - decimalPart).toFixed(2));
        }
    };


    const calculateGrandTotal = () => {
        const total = parseFloat(calculateTotal()).toFixed(2)
        const cgst = parseFloat(calculateGST() / 2).toFixed(2)
        const sgst = parseFloat(calculateGST() / 2).toFixed(2)
        const amount = parseFloat(total) + parseFloat(cgst) + parseFloat(sgst)
        const roundOff = roundAmount();
        const grandTotal = amount + roundOff;
        return grandTotal.toFixed(2);
    };

    useEffect(() => {
        if (state.terms_condition_accepted || state.is_subscribed) {
            setisAccepted(state.terms_condition_accepted)
            setisSubscribed(state.is_subscribed)
        }
    }, [state.terms_condition_accepted, state.is_subscribed])

    const handleEmail = async () => {
        let error = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email || !emailRegex.test(email)) {
            error.email = "Please enter valid email id"
            setErrors(error)
            return;
        } else {
            const data = {
                ...state,
                buyer_details: { ...state.buyer_details, email: email },
            };
            await dispatch(setData(data));
            setisSubscribed(true)
            setErrors({})
            toggle()
        }
    }

    return (
        <div className="App">
            <Header sublogo={state.sub_logo} mainlogo={state.main_logo} style={3} counterId={counter_id} />
            <Spinner loading={isLoading} />
            <div className="ReviewContainer">
                <div className="container py-3">
                    <Modal
                        isOpen={modal}
                        toggle={toggle}
                        backdrop={'static'}
                        keyboard={false}
                    >
                        <ModalHeader toggle={toggle}>Subscribe Newsletter</ModalHeader>
                        <ModalBody>
                            <div className='col-12'>
                                <input type="text" placeholder="Email Address" className="col form-control p-2 bg-custom-input" onChange={(e) => setEmail(e.target.value)} />
                                <p className='pt-1 ps-1 text-danger m-0'>{errors.email}</p>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button className='bg-custom-color-secondary' style={{ borderColor: "#AA4332" }} onClick={() => handleEmail()}>
                                Submit
                            </Button>
                            {''}
                            <Button color="secondary" onClick={toggle}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Modal>
                    {displayRazorpay && (
                        <RenderRazorpay
                            key={orderDetails.key}
                            amount={orderDetails.amount}
                            currency={orderDetails.currency}
                            name={orderDetails.name}
                            description={orderDetails.description || ""}
                            image={orderDetails.image}
                            order_id={orderDetails.order_id}
                            callback_url={orderDetails.callback_url}
                            prefill={orderDetails.prefill}
                            notes={orderDetails.notes}
                            theme={orderDetails.theme}
                            payment_gateway={orderDetails.payment_gateway}
                        />
                    )}
                    <div>
                        <h3 className="text-center m-0 d-flex justify-content-center">Review Order
                            {/* <span className='text-color-primary fs-14 ps-1 align-content-center'>({nationality})</span> */}
                        </h3>
                        {/* <h5 className="text-center m-0 text-color-primary">Indian</h5> */}
                    </div>
                    <div className='p-2 text-center rounded bg-custom-color mt-3'>
                        <h4 className='text-color-primary fw-bold m-0'>{state.sightseeing_name}</h4>
                        <label className='m-0 fs-14'>{state.product_name}</label>
                        <h6 className='mt-2'>{state.product_validity} | {currentDate}</h6>
                    </div>
                    <div className="table-responsive rounded ">
                        <table className="table table-centered mb-0 table-nowrap table-borderless " >
                            <thead>
                                <tr>
                                    <th className="col-3" style={{ fontSize: 12, textAlign: 'center' }}></th>
                                    <th className="col-3" style={{ fontSize: 12, textAlign: 'center' }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedTicketItems.map((item, index) => {
                                    return (
                                        <tr className='fw-bold' key={index}>
                                            <td className='ps-4'>
                                                <span className="fs-14">{item.type} ({item.no_of_tickets})</span>
                                            </td>
                                            <td className="text-end pe-3 fs-14">
                                                {parseFloat(item.price * item.no_of_tickets).toFixed(2)}
                                            </td>
                                        </tr>
                                    )
                                })}
                                {/* Total Calculation */}
                                <tr style={{ borderTopWidth: 1, borderColor: '#d6d6d6' }} className="text-end">
                                    <th>

                                    </th>

                                    <td className='d-flex justify-content-between pt-2 p-0'>
                                        <td className="text-end" style={{ display: 'contents' }}>
                                            <span className='fs-14'>
                                                Sub Total
                                            </span>

                                            <h6 className='pe-3 fs-14'>
                                                {parseFloat(calculateTotal()).toFixed(2)}
                                            </h6>
                                        </td>
                                    </td>
                                </tr>
                                {calculateGST() !== 0 &&
                                    <>
                                        <tr className="text-end">

                                            <th>

                                            </th>

                                            <td className='d-flex justify-content-between p-0'>
                                                <td className="text-end" style={{ display: 'contents' }}>
                                                    <span className='fs-14'>
                                                        CGST (9%)
                                                    </span>

                                                    <h6 className='pe-3 fs-14'>
                                                        {parseFloat(calculateGST() / 2).toFixed(2)}
                                                    </h6>
                                                </td>
                                            </td>
                                        </tr>
                                        <tr className="text-end">

                                            <th>

                                            </th>

                                            <td className='d-flex justify-content-between p-0'>
                                                <td className="text-end" style={{ display: 'contents' }}>
                                                    <span className='fs-14'>
                                                        SGST (9%)
                                                    </span>

                                                    <h6 className='pe-3 fs-14'>
                                                        {parseFloat(calculateGST() / 2).toFixed(2)}
                                                    </h6>
                                                </td>
                                            </td>
                                        </tr>
                                    </>
                                }
                                {roundAmount() != 0 &&
                                    <tr className="text-end">

                                        <th>

                                        </th>

                                        <td className='d-flex justify-content-between p-0'>
                                            <td className="text-end" style={{ display: 'contents' }}>
                                                <h6 className='fs-14'>
                                                    Round off
                                                </h6>

                                                <h6 className='pe-3 fs-14'>
                                                    {roundAmount()}
                                                </h6>
                                            </td>
                                        </td>
                                    </tr>
                                }
                                <tr className="text-end">

                                    <th>

                                    </th>

                                    <td className='d-flex justify-content-between p-0'>
                                        <td className="text-end" style={{ display: 'contents' }}>
                                            <h6 className='fw-bold fs-14'>
                                                Grand Total
                                            </h6>

                                            <h6 className='fw-bold pe-3 fs-14'>
                                                {parseFloat(calculateGrandTotal()).toFixed(2)}
                                            </h6>
                                        </td>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='p-2 text-center rounded bg-custom-color my-3'>
                        <h4 className='m-0'>Buyer Detail</h4>
                    </div>
                    <div className="row mt-2 justify-content-center px-2">
                        <div className="col-6 text-left ps-5 align-self-center">
                            <span className='fs-14'>Name</span>
                        </div>
                        <div className="col-6 text-start fw-bold">
                            <span className='fs-14'>{state.buyer_details.name || "-"}</span>
                        </div>
                    </div>
                    <div className="row mt-2 justify-content-center px-2">
                        <div className="col-6 text-left ps-5 align-self-center">
                            <span className='fs-14'>Email</span>
                        </div>
                        <div className="col-6 text-start fw-bold">
                            <span className='fs-14'>{state.buyer_details.email || "-"}</span>
                        </div>
                    </div>
                    <div className="row mt-2 justify-content-center px-2">
                        <div className="col-6 text-left ps-5 align-self-center">
                            <span className='fs-14'>Mobile</span>
                        </div>
                        <div className="col-6 text-start fw-bold">
                            <span className='fs-14'>{state.buyer_details.mobile || "-"}</span>
                        </div>
                    </div>
                    {/* <div className="row mt-2 justify-content-center px-2">
                        <div className="col-6 text-left ps-5 align-self-center">
                            <span className='fs-14'>Countary</span>
                        </div>
                        <div className="col-6 text-start fw-bold">
                            <span className='fs-14'>India</span>
                        </div>
                    </div> */}
                    {state.buyer_details.state &&
                        <div className="row mt-2 justify-content-center px-2">
                            <div className="col-6 text-left ps-5 align-self-center">
                                <span className='fs-14'>State</span>
                            </div>
                            <div className="col-6 text-start fw-bold">
                                <span className='fs-14'>{state.buyer_details.state}</span>
                            </div>
                        </div>
                    }
                    {state.buyer_details.city &&
                        <div className="row mt-2 justify-content-center px-2">
                            <div className="col-6 text-left ps-5 align-self-center">
                                <span className='fs-14'>City</span>
                            </div>
                            <div className="col-6 text-start fw-bold">
                                <span className='fs-14'>{state.buyer_details.city}</span>
                            </div>
                        </div>
                    }
                    {state.buyer_details.pincode &&
                        <div className="row mt-2 justify-content-center px-2">
                            <div className="col-6 text-left ps-5 align-self-center">
                                <span className='fs-14'>Pincode</span>
                            </div>
                            <div className="col-6 text-start fw-bold">
                                <span className='fs-14'>{state.buyer_details.pincode}</span>
                            </div>
                        </div>
                    }
                    {state.buyer_details.company &&
                        <div className="row mt-2 justify-content-center px-2">
                            <div className="col-6 text-left ps-5 align-self-center">
                                <span className='fs-14'>Company Name</span>
                            </div>
                            <div className="col-6 text-start fw-bold">
                                <span className='fs-14'>{state.buyer_details.company}</span>
                            </div>
                        </div>
                    }
                    {state.buyer_details.address &&
                        <div className="row mt-2 justify-content-center px-2">
                            <div className="col-6 text-left ps-5 align-self-center">
                                <span className='fs-14'>Address</span>
                            </div>
                            <div className="col-6 text-start fw-bold">
                                <span className='fs-14'>{state.buyer_details.address}</span>
                            </div>
                        </div>
                    }
                    <div className="row mt-2 justify-content-center px-2">
                        <div className="col-6 text-left ps-5 align-self-center">
                            <span className='fs-14'>ID Proof</span>
                        </div>
                        <div className="col-6 text-start fw-bold">
                            <span className='fs-14'>{state.buyer_details.id_proof || "-"}</span>
                        </div>
                    </div>
                    <div className="row mt-2 justify-content-center px-2">
                        <div className="col-6 text-left ps-5 align-self-center">
                            <span className='fs-14'>ID Proof No</span>
                        </div>
                        <div className="col-6 text-start fw-bold">
                            <span className='fs-14'>{state.buyer_details.id_proof_no || "-"}</span>
                        </div>
                    </div>
                    {state.buyer_details.gst_no &&
                        <div className="row mt-2 justify-content-center px-2">
                            <div className="col-6 text-left ps-5 align-self-center">
                                <span className='fs-14'>GST No (i)</span>
                            </div>
                            <div className="col-6 text-start fw-bold">
                                <span className='fs-14'>{state.buyer_details.gst_no}</span>
                            </div>
                        </div>
                    }
                    <div className="row justify-content-center align-items-center px-2 mt-5">
                        <div className="col-12">
                            <div className="form-check d-flex align-items-center">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="showFields"
                                    checked={isAccepted}
                                    onChange={(e) => setisAccepted(e.target.checked)}
                                />
                                <label className="form-check-label ps-3 fs-14" htmlFor="showFields">
                                    I have read and agree to the Terms and Conditions
                                    <span className="text-danger">*</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center align-items-center px-2 mt-2">
                        <div className="col-12">
                            <div className="form-check d-flex align-items-center">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="showFieldsNews"
                                    key={state.buyer_details.email}
                                    checked={isSubscribed}
                                    onChange={(e) => {
                                        if (state.buyer_details.email) {
                                            setisSubscribed(e.target.checked)
                                        } else {
                                            toggle()
                                        }
                                    }}
                                />
                                <label className="form-check-label ps-3 fs-14" htmlFor="showFieldsNews">
                                    I accept to subscribe our Newsletter
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="Footer">
                <div style={{ height: 25, width: "100%", backgroundColor: "#FAF7F2", borderBottomLeftRadius: 25, borderBottomRightRadius: 25 }}></div>
                <div className="FooterCompoenent">
                    <div onClick={() => navigate(-1)}>
                        <img src={require('../assets/icon/left_arrow.png')} className="custom-icon-main-footer me-3" />
                        <span className='fs-6'>PREVIOUS</span>
                    </div>
                    <div onClick={!isLoading && handleonClick}>
                        <span className='fs-6'>MAKE PAYMENT</span>
                        <img src={require('../assets/icon/right_arrow.png')} className="custom-icon-main-footer ms-3" />
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Revieworder;
