export const BASE_URL = "https://eternalmewar-server.bcreative.in"

export const APIENDPOINT = {
    GETALLSIGHTSEEN: "sightseeing/get/all",
    GELALLPRODUCT: "product/get/by-sightseeing",
    GETALLSALESPRODUCT: "sale-code/get/by-product",
    SENDOTP: "user/send-otp",
    VERIFYOTP: "user/verify-otp",
    SUBMITBOOKING: "user/submit-booking",
    STATES: "user/get/states",
    CITY: "user/get/cities",
    ORDERBYID: "user/get/ticket/by-order",
    DOWNLOADTICKET: "user/download/ticket/by-order",
    UPDATEPAYMENTSTATUS: "user/update/payment-status",
    GETALLSUPPORTLIST: "support/get",
    EMAILTICKET: "user/email/ticket",
    VERIFYTOKEN: "user/verify-token",
    SENDTOKEN: "user/send/verification-token"
}