import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoHomeOutline } from "react-icons/io5";
import { IoMdHelpCircleOutline } from "react-icons/io";

import { headerDate, headerTime } from "../../global/Function";
import '../../css/main.css';

function Header({ mainlogo, sublogo, style, counterId, screen }) {
    const navigate = useNavigate()
    const [Time, setTime] = useState()

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTime(headerTime())
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <React.Fragment>
            {style == 2 ?
                <header className="Header d-flex justify-content-between align-items-center">
                    {screen == "Dashboard" &&
                        <div style={{ width: '33.33%' }}>
                            <IoMdHelpCircleOutline onClick={() => navigate(`/counter/${counterId}/support-help`)} className="Icon" />
                        </div>
                    }
                    <div style={{ width: screen === "Dashboard" ? "33.33%" : '57.5%', textAlign: screen === "Dashboard" ? "center" : 'right' }}>
                        {(sublogo || mainlogo) &&
                            <img src={sublogo ? sublogo : mainlogo} alt="header-logo" className="Logo" />
                        }
                    </div>
                    <div style={{ width: screen == "Dashboard" ? "33.33%" : 'auto', textAlign: 'right' }}>
                        <div className="text-center float-end">
                            <p className={screen == "Dashboard" ? "m-0 fw-bold mt-1" : "m-0 fw-bold mt-1 text-center"} style={{ fontSize: 16 }}>{Time}</p>
                            <p className="m-0" style={{ fontSize: 11, position: 'relative', top: -5 }}>{headerDate()}</p>
                        </div>
                    </div>
                </header> :
                <header className="Header d-flex justify-content-between align-items-center">
                    <div style={{ width: '33.33%' }}>
                        <IoHomeOutline onClick={() => navigate(`/counter/${counterId}`)} className="Icon" />
                    </div>
                    <div style={{ width: '33.33%', textAlign: 'center' }}>
                        {(sublogo || mainlogo) &&
                            <img src={sublogo ? sublogo : mainlogo} alt="header-logo" className="Logo" />
                        }
                    </div>
                    <div style={{ width: '33.33%', textAlign: 'right' }}>
                        <div className="text-center float-end">
                            <p className="m-0 fw-bold mt-1" style={{ fontSize: 16 }}>{headerTime()}</p>
                            <p className="m-0" style={{ fontSize: 11, position: 'relative', top: -5 }}>{headerDate()}</p>
                        </div>
                    </div>
                </header>
            }
        </React.Fragment >
    );
}

export default Header;
