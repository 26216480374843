import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { FaChevronRight } from "react-icons/fa6";

//SVG
import Header from '../../components/common/Header';
import '../../css/main.css';

function VerifiedOTP() {
    const { counter_id } = useParams();
    const state = useSelector((state) => state);
    const navigate = useNavigate()
    return (
        <div className="App">
            <Header sublogo={state.sub_logo} mainlogo={state.main_logo} style={2} counterId={counter_id} />
            <div className="SupportContent">
                <div className="container">
                    <h3 className="text-center py-3 m-0">Payment Failed - OTP Verified</h3>
                    <div className="list-main mb-3" onClick={() => navigate(`/counter/${counter_id}/support-help/payment-failed/verified-otp/1`)}>
                        <div className="text-light pe-3">
                            <span className='text-dark'>1.</span>
                        </div>
                        <div className="list-label text-dark me-1">HRH admin panel received payment but ticket not display to customer</div>
                        {/* <img src={require('../../assets/icon/right_arrow.png')} className="custom-icon-main-footer" /> */}
                        <FaChevronRight className='custom-icon-main-list text-dark' />
                    </div>
                    <div className="list-main mb-3" onClick={() => navigate(`/counter/${counter_id}/support-help/payment-failed/verified-otp/2`)}>
                        <div className="text-light pe-3">
                            <span className='text-dark'>2.</span>
                        </div>
                        <div className="list-label text-dark me-1">HRH admin not received payment but received on payment gateway</div>
                        {/* <img src={require('../../assets/icon/right_arrow.png')} className="custom-icon-main-footer" /> */}
                        <FaChevronRight className='custom-icon-main-list text-dark' />
                    </div>
                    <div className="list-main mb-3" onClick={() => navigate(`/counter/${counter_id}/support-help/payment-failed/verified-otp/3`)}>
                        <div className="text-light pe-3">
                            <span className='text-dark'>3.</span>
                        </div>
                        <div className="list-label text-dark me-1">HRH admin panel not received payment</div>
                        {/* <img src={require('../../assets/icon/right_arrow.png')} className="custom-icon-main-footer" /> */}
                        <FaChevronRight className='custom-icon-main-list text-dark' />
                    </div>
                </div>
            </div>
            <footer className="Footer">
                <div style={{ height: 25, width: "100%", backgroundColor: "#FAF7F2", borderBottomLeftRadius: 25, borderBottomRightRadius: 25 }}></div>
                <div className="FooterCompoenent">
                    <div onClick={() => navigate(-1)}>
                        <img src={require('../../assets/icon/left_arrow.png')} className="custom-icon-main-footer me-3" />
                        <span className='fs-6'>PREVIOUS</span>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default VerifiedOTP;
