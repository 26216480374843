import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

//SVG
import Header from '../../components/common/Header';
import '../../css/main.css';

function AdminNotRecive() {
    const state = useSelector((state) => state);
    const navigate = useNavigate()


    return (
        <div className="App">
            <Header sublogo={state.sub_logo} mainlogo={state.main_logo} style={2} />
            <div className="SupportContent">
                <div className="container">
                    <h3 className="text-center py-3 m-0">How to Buy Ticket</h3>
                    <div>
                        <p className='px-3'>
                            Oops payment failed!
                        </p>
                    </div>
                </div>
            </div>
            <footer className="Footer">
                <div style={{ height: 25, width: "100%", backgroundColor: "#FAF7F2", borderBottomLeftRadius: 25, borderBottomRightRadius: 25 }}></div>
                <div className="FooterCompoenent">
                    <div onClick={() => navigate(-1)}>
                        <img src={require('../../assets/icon/left_arrow.png')} className="custom-icon-main-footer me-3" />
                        <span className='fs-6'>PREVIOUS</span>
                    </div>
                </div>
            </footer>

        </div>
    );
}

export default AdminNotRecive;
